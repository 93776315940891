import { useCatalogUrl } from '@bluheadless/ui-logic/src/hooks/useCatalogUrl'
import { cx } from '@emotion/css'
import { arrayOf, object, string } from 'prop-types'
import { useState, useEffect, memo } from 'react'
import ArrowheadRight from '../../../atoms/icons/arrowhead-right-icon'
import RichContent from '../../../particles/rich-content'
import { NavItem as NavItemStyled, NavLinkStyled, NavMenu } from './multi-drawer-menu.styled'
import MultiDrawerSubMenu from './multi-drawer-submenu'
import { useRouter } from 'next/router'
import { menuItemIsActive, menuHasActiveItems } from '@bluheadless/ui-logic/src/utils/menu'
import isEqual from 'lodash/isEqual'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'

const NavItem = memo(
	function NavItem({ item, className, children }) {
		const { asPath } = useRouter()
		const active = menuItemIsActive(item, asPath)
		const hasActiveChildren = menuHasActiveItems(item.children, asPath)

		return (
			<NavItemStyled
				className={cx(className, {
					'menu-item-active': active || hasActiveChildren,
				})}
				variant="body2"
				component="div"
			>
				{children}
			</NavItemStyled>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.className === nextProps.className && isEqual(prevProps.item, nextProps.item)
	}
)

const NavLink = ({ title, url, type, arrowIcon, ...props }) => {
	const productUrl = useCatalogUrl(url)

	let href

	if (url && type === 'product') {
		href = productUrl
	} else {
		href = url
	}

	return (
		<NavLinkStyled href={href} {...props}>
			<RichContent content={title} />
			{arrowIcon}
		</NavLinkStyled>
	)
}

const MultiDrawerMenu = ({ items, className, multiDrawerSubMenuProps, toggleSidebar, anchor, ...props }) => {
	const [activeNode, setActiveNode] = useState({})
	const { menuItemClick } = useTrackingsActions()

	useEffect(() => {
		if (!toggleSidebar) {
			setActiveNode({})
		}
	}, [toggleSidebar])

	return (
		<NavMenu className={cx('NavMenu-root', className)} aria-label="menu" {...props}>
			{items.map((item, index) => {
				const {
					children,
					cssClasses,
					id,
					level,
					path,
					url,
					target,
					title,
					urlKey,
					parentId,
					type,
					entityId,
					identifier,
				} = item
				const hasChildren = children.length > 0 && children.some((child) => child.type !== 'cms_block')
				const navLink = !hasChildren ? path ?? urlKey ?? url : null
				// title can be a string or an HTML content. If it's an HTML content, we need to clean all the tags
				// and get the text content. e.g. <strong>Title</strong> -> Title
				const ariaLabel = title.replace(/<[^>]*>?/g, '')
				return (
					<div key={id}>
						<NavItem item={item}>
							<NavLink
								className={cx('menu-item', 'level-' + level, cssClasses)}
								aria-label={ariaLabel}
								arrowIcon={hasChildren && <ArrowheadRight fontSize="middle" />}
								onClick={(e) => {
									if (hasChildren) {
										setActiveNode({ nodeId: id, parentId: parentId })
									} else {
										menuItemClick({ event: e, entityType: type, entityId, entityName: identifier })
									}
								}}
								tabIndex={index}
								target={target}
								title={title}
								type={type}
								underline="none"
								url={navLink}
							/>
						</NavItem>
						{hasChildren && (
							<MultiDrawerSubMenu
								items={children}
								parentItems={items}
								activeNode={activeNode}
								setActiveNode={setActiveNode}
								parentItemId={id}
								anchor={anchor}
								toggleMenuId={id}
								{...multiDrawerSubMenuProps}
							/>
						)}
					</div>
				)
			})}
		</NavMenu>
	)
}

MultiDrawerMenu.propTypes = {
	items: arrayOf(object).isRequired,
	anchor: string,
	multiDrawerSubMenuProps: object,
}

export default MultiDrawerMenu
