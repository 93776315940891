import { PAGE_PATH_CUSTOMER, PAGE_PATH_LOGIN } from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useNotifyMessage } from '@bluheadless/ui-logic/src/providers/notify-message'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import AccountIcon from '@bluheadless/ui/src/atoms/icons/account-icon'
import MapPinIcon from '@bluheadless/ui/src/atoms/icons/map-pin-icon'
import Link from '@bluheadless/ui/src/molecules/link'
import { ChangeCountryLanguage } from '@bluheadless/ui/src/organisms/change-country-language'
import { ButtonIcon } from '@bluheadless/ui/src/organisms/header/header.styled'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme, useThemeProps } from '@mui/system'
import dynamic from 'next/dynamic'
import { elementType, object, oneOf, shape } from 'prop-types'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import SupportIcon from '../../atoms/icons/support-icon'
import MultiDrawerMenu from '../../molecules/menu/multi-drawer-menu'
import NotifyMessage from '../../molecules/notify-message'
import SearchTrigger from '../../molecules/search'
import Block from '../../particles/cms-block/block'
import HeaderError from './header-error'
import { StorelocatorLinkStyled } from './header.styled'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'

const HeaderCompact = dynamic(() => import('./header-compact'))
const HeaderCompactInlineSearch = dynamic(() => import('./header-compact-inline-search'))
const HeaderFull = dynamic(() => import('./header-full'))
const HeaderLight = dynamic(() => import('./header-light'))
const HeaderCompactMini = dynamic(() => import('./header-mini-compact'))
const HeaderCompactInlineSearchMini = dynamic(() => import('./header-mini-compact-inline-search'))
const HeaderFullMini = dynamic(() => import('./header-mini-full'))
const HeaderRestricted = dynamic(() => import('./header-restricted'))

const Header = ({ notifyMessageProps, look, menuItems, variant, ...inProps }) => {
	const props = useThemeProps({
		name: 'BHHeader',
		props: inProps,
	})

	const { menuProps, multiDrawerMenuProps } = props
	const menuPropsComputed = { ...menuProps, items: menuItems }
	const multiDrawerMenuPropsComputed = { ...multiDrawerMenuProps, items: menuItems }
	const theme = useTheme()
	const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

	const notifyMessageBlocks = useNotifyMessage()

	const { entityType: pageType } = usePageContext()

	const {
		notifyMessage: { cmsBlockIdentifier, cmsBlockIdentifierAdditionalContent, position, closeEnabled },
		seoMeta,
		logo: {
			light: { image: logoLightSrc, width: logoLightWidth, height: logoLightHeight },
			dark: { image: logoDarkSrc, width: logoDarkWidth, height: logoDarkHeight },
		},
		customerCare: { enabled: customerCareEnabled, path: customerCarePath },
	} = useConfig()

	const [{ isLoggedIn }] = useUser()

	const content = notifyMessageBlocks?.find((block) => block.identifier === cmsBlockIdentifier)?.content
	const additionalContent = notifyMessageBlocks?.find(
		(block) => block.identifier === cmsBlockIdentifierAdditionalContent
	)?.content

	const message = useMemo(() => {
		return lgUp ? (
			<Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
				<Grid item maxWidth="max-content">
					<Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
						<ChangeCountryLanguage
							className="change-country-language-trigger-header"
							showCurrencySymbol={false}
							showLanguage={false}
							showFlag={false}
						/>

						{customerCareEnabled && (
							<Link underline="none" href={customerCarePath} color="secondary">
								<ButtonIcon Icon={props.contactsIcon} variant="icon" />

								<FormattedMessage id="header_navigation_customer_care" />
							</Link>
						)}
					</Stack>
				</Grid>
				<Grid item xs={4} flexGrow={1}>
					{content ? <Block content={content} /> : null}
				</Grid>
				<Grid item maxWidth="max-content">
					<Stack direction="row" spacing={3} sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
						<Link underline="none" href={isLoggedIn ? PAGE_PATH_CUSTOMER : PAGE_PATH_LOGIN} color="secondary">
							<ButtonIcon
								badgeProps={isLoggedIn ? { color: 'success', variant: 'dot' } : undefined}
								Icon={AccountIcon}
								variant="icon"
							/>

							<FormattedMessage id="sidebar_navigation_account" />
						</Link>

						<StorelocatorLinkStyled
							className="header-storelocator-link"
							buttonProps={{
								StartIcon: MapPinIcon,
								variant: 'text',
								iconProps: { fontSize: 'middle', color: 'secondary' },
								label: <FormattedMessage id={'footer_store_locator_link_label'} />,
							}}
						/>
					</Stack>
				</Grid>
			</Grid>
		) : content ? (
			<Block content={content} />
		) : null
	}, [content, customerCareEnabled, customerCarePath, isLoggedIn, lgUp, props.contactsIcon])

	const decoratedNotifyMessageProps = {
		...notifyMessageProps,
		additionalContent: additionalContent ? <Block content={additionalContent} /> : null,
		message,
		position: position,
		closeEnabled: closeEnabled,
		type: pageType === 'product' ? 'product' : props.type,
	}

	const logoProps = {
		src: look === 'dark' ? logoLightSrc : logoDarkSrc,
		alt: seoMeta?.title,
		originalWidth: look === 'dark' ? logoLightWidth : logoDarkWidth,
		originalHeight: look === 'dark' ? logoLightHeight : logoDarkHeight,
		placeholder: 'empty',
	}

	let header
	switch (variant) {
		case 'compact':
			header = lgUp ? (
				<HeaderCompact
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					menuProps={menuPropsComputed}
				/>
			) : (
				<HeaderCompactMini
					notifyMessageProps={decoratedNotifyMessageProps}
					variant={variant}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
					logoProps={logoProps}
				/>
			)
			break
		case 'compact-inline-search':
			header = lgUp ? (
				<HeaderCompactInlineSearch
					look={look}
					notifyMessageProps={{
						...decoratedNotifyMessageProps,
						look: look === 'dark' ? 'light' : 'dark',
					}}
					logoProps={logoProps}
					{...props}
					menuProps={menuPropsComputed}
				/>
			) : (
				<HeaderCompactInlineSearchMini
					look={look}
					notifyMessageProps={{
						...decoratedNotifyMessageProps,
						look: look === 'dark' ? 'light' : 'dark',
					}}
					logoProps={logoProps}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
				/>
			)
			break
		case 'full':
			header = lgUp ? (
				<HeaderFull
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					menuProps={menuPropsComputed}
				/>
			) : (
				<HeaderFullMini
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
				/>
			)
			break
		case 'light':
			header = <HeaderLight logoProps={logoProps} {...props} />
			break
		case 'restricted':
			header = <HeaderRestricted logoProps={logoProps} {...props} />
			break
		case 'error':
			header = <HeaderError logoProps={logoProps} {...props} />
			break
		default:
			header = null
	}

	return header
}

Header.defaultProps = {
	contactsIcon: SupportIcon,
	look: 'light',
	multiDrawerMenuProps: {
		...MultiDrawerMenu.defaultProps,
	},
	notifyMessageProps: {
		...NotifyMessage.defaultProps,
	},
	variant: 'compact',
}

Header.propTypes = {
	/**
	 * Contacts icon
	 */
	contactsIcon: elementType,
	/**
	 * Header look (when variant is compact-inline-search)
	 */
	look: oneOf(['dark', 'light']),
	/**
	 * Menu props
	 */
	menuProps: object,
	/**
	 * Multi-drawer menu props
	 */
	multiDrawerMenuProps: object,
	/**
	 * Notify message props
	 */
	notifyMessageProps: shape(NotifyMessage.propTypes),
	/**
	 * Search trigger props
	 */
	searchTriggerProps: shape(SearchTrigger.propTypes),
	/**
	 * Header variant
	 */
	variant: oneOf(['compact', 'compact-inline-search', 'full', 'light', 'restricted', 'error']),
}

export default Header
