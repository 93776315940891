/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SupportIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path
			fillRule="evenodd"
			d="M12 24q-2.4 0-4.7-.9-2.2-1-3.8-2.6-1.6-1.6-2.6-3.8Q0 14.4 0 12q0-2.4.9-4.7 1-2.2 2.6-3.8Q5.1 1.9 7.3.9 9.6 0 12 0q2.4 0 4.7.9 2.2 1 3.8 2.6 1.6 1.6 2.6 3.8.9 2.3.9 4.7 0 2.4-.9 4.7-1 2.2-2.6 3.8-1.6 1.6-3.8 2.6-2.3.9-4.7.9zm0-1.6q2.1 0 4-.8 2-.8 3.4-2.2 1.4-1.4 2.2-3.4.8-1.9.8-4t-.8-4q-.8-2-2.2-3.4Q18 3.2 16 2.4q-1.9-.8-4-.8t-4 .8q-2 .8-3.4 2.2Q3.2 6 2.4 8q-.8 1.9-.8 4t.8 4q.8 2 2.2 3.4Q6 20.8 8 21.6q1.9.8 4 .8zM10.6 9.2H8Q8 7.1 9.2 6q1.1-1.2 3.2-1.2 1.9 0 3 .9 1.1.8 1.1 2.4 0 .9-.3 1.5-.3.7-.7 1.1l-.9.9q-.4.4-.7.9-.3.6-.3 1.2h-2.5q0-.8.2-1.5.3-.6.6-1 .4-.4.7-.8.4-.3.6-.8.3-.4.3-.9 0-1.3-1.4-1.3-1.5 0-1.5 1.8zm1.7 6q.7 0 1.1.5.5.4.5 1.1 0 .8-.5 1.2-.4.5-1.1.5-.7 0-1.2-.5-.4-.4-.4-1.2 0-.7.4-1.1.5-.5 1.2-.5z"
			fill="currentColor"
		/>
	</svg>
))
SupportIconSvg.displayName = 'SupportIconSvg'

const SupportIcon = forwardRef((props, ref) => <Icon component={SupportIconSvg} ref={ref} {...props} />)
SupportIcon.displayName = 'SupportIcon'

SupportIcon.defaultProps = {
	...Icon.defaultProps,
}
SupportIcon.propTypes = {
	...Icon.propTypes,
}

export default SupportIcon
export { SupportIconSvg }
